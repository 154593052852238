<template>
  <div class="actions">
    <b-button
      class="px-3"
      variant="info"
      size="sm"
      @click="downloadReport"
      :disabled="isDownloadingReport"
    >
      <span class="d-flex align-items-center">
        <half-circle-spinner
          :animation-duration="1500"
          :size="20"
          v-if="isDownloadingReport"
        />
        <i class="fa fa-download" aria-hidden="true" v-else></i>
        <span class="mx-2">
          {{
            isDownloadingReport
              ? $t('call-survey.list.table.actionsButtons.downloading')
              : $t('call-survey.list.table.actionsButtons.report')
          }}
        </span>
      </span>
    </b-button>

    <router-link :to="`/post-call-surveys/${rowData.id}/survey-details`">
      <b-button variant="info" size="sm" class="px-3">
        <i class="fa fa-eye" aria-hidden="true"></i>
      </b-button>
    </router-link>

    <b-dropdown right id="extra-actions" toggle-class="px-3 py-2">
      <template #button-content>
        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
      </template>
      <b-dropdown-item :to="`/post-call-surveys/${rowData.id}/cdrs`">
        <i class="fa fa-list" aria-hidden="true"></i>
        <span class="mx-2">
          {{ $t('call-survey.list.table.actionsButtons.cdrs') }}
        </span>
      </b-dropdown-item>

      <b-dropdown-item
        @click="showCallsAnalytics"
        :disabled="isFetchingAnalytics"
      >
        <i
          class="fa fa-bar-chart"
          aria-hidden="true"
          v-if="!isFetchingAnalytics"
        ></i>
        <half-circle-spinner
          :animation-duration="1500"
          :size="20"
          v-if="isFetchingAnalytics"
        />
        <span class="mx-2">
          {{ $t('call-survey.list.table.actionsButtons.calls-analytics') }}
        </span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import CallSurveyService from '../../../../services/call-survey.service'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import Swal from 'sweetalert2'
import PostCallSurveyService from '../../../../services/post-call-survey.service'

export default {
  components: {
    HalfCircleSpinner
  },
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDeleting: false,
      isStarting: false,
      isPausing: false,
      isResuming: false,
      isFinishing: false,
      isDownloadingReport: false,
      isFetchingMetrics: false,
      isFetchingAnalytics: false
    }
  },
  computed: {
    step() {
      switch (this.rowData.status) {
        case 'customers-inserted':
          return 'assign-agents'

        default:
          return 'attach-customers'
      }
    }
  },
  methods: {
    async startSurvey() {
      try {
        Swal.fire({
          title: this.$t('call-survey.list.modals.start.title'),
          html: this.$t('call-survey.list.modals.start.html'),
          showCancelButton: true,
          confirmButtonText: this.$t(
            'call-survey.list.modals.start.confirmButtonText'
          ),
          cancelButtonText: this.$t(
            'call-survey.list.modals.start.cancelButtonText'
          ),
          customClass: {
            confirmButton: 'btn btn-primary btn-sm',
            cancelButton: ' btn btn-sm mx-2'
          },
          buttonsStyling: false
        }).then(async res => {
          if (res.value) {
            try {
              this.isStarting = true
              await CallSurveyService.startSurvey(this.rowData.id)
              this.toast(this.$t('call-survey.list.toasts.survey-started'))
              this.$emit('refetch')
            } catch (error) {
              const message = error.response
                ? error.response.data.message
                : error.message
              this.toast(message, {
                type: 'error'
              })
            } finally {
              this.isStarting = false
            }
          }
        })
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    },
    async finishSurvey() {
      try {
        Swal.fire({
          title: this.$t('call-survey.list.modals.finish.title'),
          html: this.$t('call-survey.list.modals.finish.html'),
          showCancelButton: true,
          confirmButtonText: this.$t(
            'call-survey.list.modals.finish.confirmButtonText'
          ),
          cancelButtonText: this.$t(
            'call-survey.list.modals.finish.cancelButtonText'
          ),
          customClass: {
            confirmButton: 'btn btn-primary btn-sm',
            cancelButton: ' btn btn-sm mx-2'
          },
          buttonsStyling: false
        }).then(async res => {
          if (res.value) {
            try {
              this.isFinishing = true
              await CallSurveyService.finishSurvey(this.rowData.id)
              this.toast(this.$t('call-survey.list.toasts.survey-finished'))
              this.$emit('refetch')
            } catch (error) {
              const errorMessage = error.response
                ? error.response.data.message
                : error.message

              this.toast(errorMessage, {
                type: 'error'
              })
            } finally {
              this.isFinishing = false
            }
          }
        })
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    },
    async pauseSurvey() {
      try {
        Swal.fire({
          title: this.$t('call-survey.list.modals.pause.title'),
          html: this.$t('call-survey.list.modals.pause.html'),
          showCancelButton: true,
          confirmButtonText: this.$t(
            'call-survey.list.modals.pause.confirmButtonText'
          ),
          cancelButtonText: this.$t(
            'call-survey.list.modals.pause.cancelButtonText'
          ),
          customClass: {
            confirmButton: 'btn btn-primary btn-sm',
            cancelButton: ' btn btn-sm mx-2'
          },
          buttonsStyling: false
        }).then(async res => {
          if (res.value) {
            try {
              this.isPausing = true
              await CallSurveyService.pauseSurvey(this.rowData.id)
              this.toast(this.$t('call-survey.list.toasts.survey-paused'))
              this.$emit('refetch')
            } catch (error) {
              const errorMessage = error.response
                ? error.response.data.message
                : error.message

              this.toast(errorMessage, {
                type: 'error'
              })
            } finally {
              this.isPausing = false
            }
          }
        })
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    },
    async resumeSurvey() {
      try {
        Swal.fire({
          title: this.$t('call-survey.list.modals.resume.title'),
          html: this.$t('call-survey.list.modals.resume.html'),
          showCancelButton: true,
          confirmButtonText: this.$t(
            'call-survey.list.modals.resume.confirmButtonText'
          ),
          cancelButtonText: this.$t(
            'call-survey.list.modals.resume.cancelButtonText'
          ),
          customClass: {
            confirmButton: 'btn btn-primary btn-sm',
            cancelButton: ' btn btn-sm mx-2'
          },
          buttonsStyling: false
        }).then(async res => {
          if (res.value) {
            try {
              this.isResuming = true
              await CallSurveyService.resumeSurvey(this.rowData.id)
              this.toast(this.$t('call-survey.list.toasts.survey-resumed'))
              this.$emit('refetch')
            } catch (error) {
              const errorMessage = error.response
                ? error.response.data.message
                : error.message

              this.toast(errorMessage, {
                type: 'error'
              })
            } finally {
              this.isResuming = false
            }
          }
        })
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    },
    async downloadReport() {
      try {
        this.isDownloadingReport = true
        await PostCallSurveyService.downloadReport(this.rowData.id)
        this.toast(this.$t('call-survey.list.toasts.report-downloaded'))
        this.$emit('refetch')
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      } finally {
        this.isDownloadingReport = false
      }
    },
    async showMetrics() {
      try {
        this.isFetchingMetrics = true
        const res = await CallSurveyService.getSurveyMetrics(this.rowData.id)
        this.$emit('showMetrics', res.data)
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      } finally {
        this.isFetchingMetrics = false
      }
    },
    async deleteSurvey() {
      try {
        this.isDeleting = true
        await CallSurveyService.deleteSurvey(this.rowData.id)
        this.toast('survey deleted successfully')
        this.$emit('refetch')
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      } finally {
        this.isDeleting = false
      }
    },
    async showCallsAnalytics() {
      try {
        const res = await PostCallSurveyService.fetchSurveyAnalytics(
          this.rowData.id
        )
        this.$emit('showCallsAnalytics', res)
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
</style>
